<template>
  <div class="image">
    <div class="image__wrapper">
      <nuxt-img
        width="289"
        height="349"
        format="webp"
        :placeholder="[289, 349]"
        :src="src"
        alt="Persoon" />
      <ImagesSvg
        v-if="hasArrow"
        class="arrow"
        :class="{ 'arrow--flip': arrowConfig?.facing === 'left' }"
        icon="curved-arrow"
        :height="arrowConfig.height"
        :width="arrowConfig.width" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PersonOverlayProps } from '@/types/person-overlay';

const props = withDefaults(defineProps<PersonOverlayProps>(), {
  hasArrow: false,
  arrowConfig: () => ({
    rotation: '0deg',
    width: 55,
    height: 96,
    facing: 'right',
    top: 46,
    left: 30,
  }),
});

const top = computed(() => pxToRem(props.arrowConfig?.top ?? 0));
const left = computed(() => pxToRem(props.arrowConfig?.left ?? 0));
</script>

<style lang="scss">
.image {
  position: relative;
  display: flex;
  justify-content: center;

  &__wrapper {
    display: inline-flex;
    position: relative;
  }

  img {
    position: relative;
    display: block;
    margin-inline: auto;
    object-fit: contain;
    height: 100%;
    width: px(289);
    max-width: 80vw;
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90%;
    background: white;
    z-index: 1;
    border-radius: px(8);
  }
}

.arrow {
  position: absolute;
  z-index: 3;
  color: $c-orange-1;
  /* stylelint-disable-next-line csstree/validator */
  top: v-bind(top);
  /* stylelint-disable-next-line csstree/validator */
  left: v-bind(left);
  /* stylelint-disable-next-line csstree/validator */
  rotate: v-bind('arrowConfig.rotation');

  &--flip {
    transform: scaleY(-1);
  }
}
</style>
types/person-overlay
